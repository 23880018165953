document.addEventListener("turbolinks:load", function() {
  document.querySelectorAll('#wechatPaymentBtn').forEach(function(wechatPayment){
    wechatPayment.addEventListener('click', function(event) {
      event.preventDefault()
      let pay = JSON.parse(wechatPayment.dataset.pay_params)
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest', {
          "appId": pay.appId,
          "timeStamp": pay.timeStamp,
          "nonceStr": pay.nonceStr,
          "package": pay.package,
          "signType": pay.signType,
          "paySign": pay.paySign
        },
        function(res){
          if(res.err_msg == "get_brand_wcpay_request:ok" ){
            alert('支付成功!')
            location.reload();
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          }else{
            alert(res.err_code+res.err_desc+res.err_msg);
          }
        });

    });

  });
})
