import {getMetaValue} from 'src/shared/meta';
var fetch_student_stop = false;

document.addEventListener("turbolinks:load", function() {
  document.querySelectorAll('#ajaxsearchstudents').forEach(function(obj){
    let result =  document.getElementById('result');
    obj.addEventListener('input', (e) => {
      //result.textContent = "Inputted text: " + e.data;

      fetch('/enroll_students/students.json?name='+e.data, {
        method: 'GET',
        credentials: "include",
        headers: {
          "X-CSRF-Token": getMetaValue("csrf-token")
        },
      })
      .then(function(response) {
        return response.json();
      })
      .then(function(jsonState){
        console.log(jsonState)
        let tmpHtml = '';
        jsonState["students"].forEach(function(ele){
           tmpHtml += '<div class="form-check"><input class="form-check-input" name="enroll_student[studentids][]" type="checkbox" value=' + ele['id'] + ' id="checkDefault' + ele['id'] +'">'
           tmpHtml += '<label class="form-check-label" for="checkDefault' + ele['id'] +'"><span>'+ele['name']+ ele['card_id']+'</span></div>'
        })
        result.innerHTML = tmpHtml;
        console.log(tmpHtml)
      })
    });
  })
})

