import {showAlert} from 'src/shared/show-alert';

document.addEventListener("turbolinks:load", function() {
  document.querySelectorAll('.js-sms-code-get').forEach(function(sendSmsCode){
    var isSending = false
    let url = sendSmsCode.dataset.url
    let timer_events = function(s) {
      var interval = setInterval((function(){
        sendSmsCode.style.display = 'none'
        let timer_div = document.getElementById('timer')
        let times_run = s
        var start = Date.now();
        var textNode = document.createTextNode(times_run);
        timer_div.innerHTML = '';
        timer_div.appendChild(textNode);
        return function() {
          if(times_run < 1 && timer_div) {
            clearInterval(interval);
            timer_div.innerHTML = '';
            sendSmsCode.style.display = ''
          }
          times_run = (s - Math.floor((Date.now()-start)/1000));
          textNode.data = times_run;
        };
      }()), 1000);
    }


    sendSmsCode.addEventListener('click', function(event) {
      const captcha_type_field      = document.getElementById('captcha_type');
      const user_area_code_field     = document.getElementById('user_area_code');
      const user_mobile_number_field = document.getElementById('user_mobile_number');
      let timerSelector       = document.getElementsByClassName('js-sms-code-waiting')[0]
      let validationCodeToken = document.getElementsByClassName('js-sms-code-token')[0]

      if (isSending) {
        return
      }else{
        isSending = true
      }

      if (user_area_code_field && user_mobile_number_field) {
        let mobile = "+"+ user_area_code_field.value +","+ user_mobile_number_field.value
        sendSmsCode.innerText = '发送中...'
        console.log(mobile)
        $.post(url, {
          authenticity_token: $('meta[name=csrf-token]').attr('content'),
          captcha_type: captcha_type_field.value,
          mobile: mobile,
          site_number: $("input[name=site_number]").val(),
          //geetest_challenge: $("input[name=geetest_challenge]").val(),
          //geetest_validate: $("input[name=geetest_validate]").val(),
          //geetest_seccode: $("input[name=geetest_seccode]").val()
        }, function (data) {
          isSending = false
          sendSmsCode.innerText = '获取验证码'
          if (data.code == '1111') {
            validationCodeToken.value = data.code_token
            timer_events(60)
          } else {
            showAlert(data.msg)
            if (window.geetest) {
              window.geetest.reset()
            }
          }
        })
      }

    })
  });
})
