import {getMetaValue} from 'src/shared/meta.js'

export const  pcWechatLogin = function(login_url, ref) {
  fetch(login_url, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    dataType: 'json',
    headers: {
      "X-CSRF-Token": getMetaValue("csrf-token")
    },
  })
  .then(function(response) {
    response.json()
    .then(function(data){
      console.log(data.msg);
      if(data.code == 1){
        clearInterval(ref);
        const info = document.querySelector('.info')
        info.style.display = 'block';
        info.innerText = data.msg
      }
    })
  }).catch(function(e){
    console.log('error:' + e.toString());
  })

}
