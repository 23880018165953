import wx from 'weixin-js-sdk'

document.addEventListener("turbolinks:load", function() {
  var wechatShareBtn = document.querySelector("#wechatShareBtn");
  if(wechatShareBtn) {
      let share = JSON.parse(wechatShareBtn.dataset.wx_params)
      wx.config({
        debug: false,
        appId: share.appId,
        timestamp: share.timeStamp,
        nonceStr: share.nonceStr,
        signature: share.signature,
        jsApiList:['checkJsApi', 'updateAppMessageShareData', 'updateTimelineShareData']
      });
      wx.ready(function () {
        wx.updateAppMessageShareData({
          title: share.title,
          desc: share.desc,
          link: share.link,
          imgUrl: share.imgUrl,
          success: function () {
          }
        });
        //分享朋友圈
        wx.updateTimelineShareData({
          title: share.title,
          link: share.link,
          imgUrl: share.imgUrl,
          success: function () {
          }
        });
      })
  }
})

