import {getMetaValue} from 'src/shared/meta.js'
import {pcWechatLogin} from 'src/shared/pc-wechat-login.js'
import {Modal} from "bootstrap"
import {isMobile, isWechat} from 'src/shared/device.js'

document.addEventListener("turbolinks:load", function() {
  const btn = document.getElementById('btn-wechat-login-pc');
  const btnBusy = document.getElementById('btn-busy');
  const btnClient = document.getElementById('btn-wechat-login-client');
  const img = document.getElementById('pc-wechat-gzh');
  const modal = document.getElementById('loginModal');
  console.log(isWechat());
  console.log(isMobile());

  if(btn && btnClient){
    if (isWechat()){
      btn.style.display = 'none';
      btnClient.style.display = 'inline';
    } else if (isMobile()){
      btn.style.display = 'inline';
      btnClient.style.display = 'none';
    } else {
      btn.style.display = 'inline';
    }
  }

  if(btn && img && modal && !isWechat()) {
    const options = {}
    var myModal = new Modal(document.getElementById('loginModal'), options)
    modal.addEventListener('hidden.bs.modal', function (event) {
      location.reload();
    })
    const url = btn.dataset.url
    const login_url = btn.dataset.loginurl

    btnClient.addEventListener('click', function(event){
      btnClient.style.display = 'none';//setAttribute('display','none');
      btnBusy.style.display = 'inline';
    })

    btn.addEventListener('click', function(event){
      btn.style.display = 'none';//setAttribute('display','none');
      btnBusy.style.display = 'inline';
      event.preventDefault()
      myModal.show();

      fetch(url, {
        method: 'GET',
        mode: 'cors',
        credentials: 'include',
        dataType: 'json',
        headers: {
          "X-CSRF-Token": getMetaValue("csrf-token")
        },
      })
      .then(function(response) {
        response.json()
          .then(function(data){
            console.log(data.code)
            console.log(data.ticket)
            img.src = 'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=' + data.ticket

            // 循环调用
            var ref = setInterval(function() {
              //fetch user
              pcWechatLogin(login_url + '?code=' + data.code, ref)
            }, 2000);


          })
      }).catch(function(e){
        console.log('error:' + e.toString());
      })
    })
  }
})

