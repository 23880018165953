var ua = navigator.userAgent.toLowerCase()
function isAndroid() {
  return (/android/i).test(ua)
}

function isIos() {
  return (/iphone|ipad|ipod/i).test(ua)
}

function isWechat() {
  return (/micromessenger/i).test(ua)
}
function isMobile() {
  return (/android|iphone|ipad|ipod|micromessenger/i).test(ua)
}

export {isMobile, isAndroid,  isIos,  isWechat };
