require("@rails/ujs").start()
require("turbolinks").start()
import ClipboardJS from "clipboard"
import './mainv2.scss'
import {Tab, Tooltip} from "bootstrap"
import 'src/shared/wechat-config-h5.js'
import 'src/shared/wechat-login-pc.js'
import 'src/shared/fetch_site_students.js'

// Wait for turbolinks load event
$(document).on('turbolinks:load', () => {
  $('[data-toggle="tooltip"]') || $('[data-toggle="tooltip"]').tooltip()
  $('.alert') || $('.alert').alert()
  $('[data-toggle="popover"]') || $('[data-toggle="popover"]').popover()
  $('.toast') || $('.toast').toast({ autohide: false })
  $('#toast') || $('#toast').toast('show')
  $('myCarousel') || $('myCarousel').carousel({
    interval: 2000
  })
  var clipboard = new ClipboardJS('.copy-btn');

  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new Tooltip(tooltipTriggerEl)
})

})

$(document).on('turbolinks:load', () => {
  // Selecting all the images.
  const images = document.querySelectorAll("img.lazy");
  // The API options: when 20% of the image is visible.
  const imgOptions = {
    threshold: 0.2
  };
  // Calling the API constructor.
  const imgObserver = new IntersectionObserver((entries, imgObserver) => {
    entries.forEach((entry) => {
      // If the image is not visible.
      if (!entry.isIntersecting) return;

      // If it's visible.
      const img = entry.target;
      img.src = img.dataset.src;
      imgObserver.unobserve(entry.target);
    });
  }, imgOptions);

  // Observing the images.
  images.forEach((img) => {
    imgObserver.observe(img);
  });
})

$(document).on('turbolinks:load', () => {
  var triggerTabList = [].slice.call(document.querySelectorAll('#myTab a'))
  triggerTabList.forEach(function (triggerEl) {
    var tabTrigger = new Tab(triggerEl)

    triggerEl.addEventListener('click', function (event) {
      event.preventDefault()
      tabTrigger.show()
    })
  })
})
