import {getMetaValue} from 'src/shared/meta';
var fetch_state_stop = false;

document.addEventListener("turbolinks:load", function() {
  document.querySelectorAll('.js-fetch-order-state').forEach(function(obj){
    let csshaze =  document.getElementById('csshaze');
    (function myLoop(i) {
      setTimeout(function() {
        fetch(obj.dataset.stateurl, {
          method: 'GET',
          credentials: "include",
          headers: {
            "X-CSRF-Token": getMetaValue("csrf-token")
          },
        })
        .then(function(response) {
          return response.json();
        })
        .then(function(jsonState){
          console.log(jsonState["state"])
          if(jsonState["state"] != "待支付") {
            if (jsonState["state"] == "待发货") {
              obj.innerHTML = '<span class="text-success">已支付成功!</span> <a href="/site_orders">查看我的订单</a>'
            }else{
              obj.innerHTML = '<span class="text-danger">错误!</span>订单状态已经已经改变，请检查!'
            }
            if (csshaze) {
              csshaze.style.display = "block"
              csshaze.innerHTML = jsonState["state"]
            }
            fetch_state_stop = true;
            console.log(fetch_state_stop)
          }
        })
        console.log(fetch_state_stop)
        if (fetch_state_stop == true){
          console.log('stop fetch')
          clearTimeout(myLoop);
        }else if(--i){
          console.log('goon fetch')
          myLoop(i)
        };   //  decrement i and call myLoop again if i > 0
      }, 3000)
    })(40);                   //  pass the number of iterations as an argument
  })
})

